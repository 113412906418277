import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import Header from "../components/header";
import MaintainanceImage from "../images/under_construction.svg";

const UnderMaintainancePageWrapper = styled(Layout)``;

const UnderMaintainancePage = ({ data }) => {
  return (
    <UnderMaintainancePageWrapper>
      <SEO title={data.site.siteMetadata.title} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="flex flex-col justify-center items-center p-8">
        <img
          src={MaintainanceImage}
          alt="Under Maintainance"
          className="w-full sm:w-4/12 mb-8"
        />
        <h2 className="text-2xl md:text-3xl font-bold text-center">Page is Under Maintainance</h2>
      </div>
    </UnderMaintainancePageWrapper>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default UnderMaintainancePage;
